.header {
    background-color: #ccd9ff;
    position: sticky;
    top: 0;
    width: 100%;
    margin-bottom: 3px;
}

.logo {
    display: inline-block;
    color: var(--textColor);
    font-size: 32px;
    padding-left: 3px;
}

.nav {
    width: 100%;
    height: 75%;
    position: fixed;
    background-color: #ccd9ff;
    overflow: hidden;
}

.menu a {
    display: block;
    padding: 9px;
    color: var(--textColor)
}

.menu a:hover {
    background-color: #6b7db3;
}

.nav {
    max-height: 0;
    transition: max-height .5s ease-out;
}

.hamb {
    cursor: pointer;
    float: right;
    padding: 20px 20px;
}

.hamb-line {
    background: var(--textColor);
    display: block;
    height: 2px;
    position: relative;
    width: 24px;
}

.hamb-line::before,
.hamb-line::after {
    background: var(--textColor);
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    transition: all .2s ease-out;
    width: 100%;
}

.hamb-line::before {
    top: 4px;
}

.hamb-line::after {
    top: -4px;
}

.side-menu {
    display: none;
}

.side-menu:checked~nav {
    max-height: 100%;
}

.side-menu:checked~.hamb .hamb-line {
    background: transparent;
}

.side-menu:checked~.hamb .hamb-line::before {
    transform: rotate(-45deg);
    top: 0;
}

.side-menu:checked~.hamb .hamb-line::after {
    transform: rotate(45deg);
    top: 0;
}

@media (min-width: 768px) {
    .nav {
        max-height: none;
        top: 0;
        position: relative;
        float: right;
        width: fit-content;
        background-color: transparent;
    }

    .menu li {
        float: left;
        height: 43px;
    }

    .menu a {
        background-color: #c2cef1;
        color: var(--txtColor);
        border-color: #3c818d;
        margin-top: 2px;
        margin-left: 5px;
        margin-bottom: -5px;
    }

    .menu a:hover {
        background-color: transparent;
        background-color: #9faac9;
    }

    .hamb {
        display: none;
    }
}